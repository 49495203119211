const $ = require('jquery');

window.toggleModal = function(id) {
    let $modal = $(id);
    let $body = $('body');

    if($modal.length === 0) {
        throw `Modal with "${id}" does not exist`;
    }

    if($modal.length < 1) {
        throw `Modal with "${id}" there's more than one`;
    }

    if($modal.hasClass('active-modal')) {
        $modal.trigger('pre-modal-close');
    } else {
        $modal.trigger('pre-modal-open');
    }

    $modal.toggleClass('opacity-0');
    $modal.toggleClass('pointer-events-none');
    $modal.toggleClass('active-modal');
    $modal.show();

    if($body.find('div.modal.active-modal').length > 0) {
        $body.addClass('has-active-modal');
    } else {
        $body.removeClass('has-active-modal');
    }

    let $header = $('header');
    let $headerHeight = $header.outerHeight();
    let $next = $header.next();
    let $nextHeight = parseInt($next.css('margin-top'));
    $header.toggleClass('hidden');
    if($header.hasClass('hidden')) {
        $next.css('margin-top', $headerHeight + $nextHeight);
    }else{
        $next.css('margin-top', '');
    }

    if($modal.hasClass('active-modal')) {
        $modal.trigger('modal-open');
    } else {
        $modal.trigger('modal-close');
    }
}

$(document).ready(function() {
    //Open modal
    $('[data-modal]').click(function () {
        toggleModal('#' + $(this).data('modal'));
    });
    //Click outside the modal-body
    $('.modal-overlay').click(function () {
        if($('body').hasClass('has-active-modal')) {
            let modalId = $(this).closest('.modal').attr('id');
            toggleModal(`#${modalId}`);
        }
    });

    $('.user-confirmation-modal .user-confirmation-confirm').click(function () {
        let that = $(this);
        let modal = that.closest('.modal');
        let modalId = modal.attr('id');
        modal.trigger('user-confirmed');
        toggleModal(`#${modalId}`);
    });

    $('.modal .modal-close').click(function () {
        let that = $(this);
        let modal = that.closest('.modal');
        let modalId = modal.attr('id');
        toggleModal(`#${modalId}`);
    });

    //Esc key
    $(document).keydown(function (e) {
        e = e || window.event
        let isEscape;
        if ("key" in e) {
            isEscape = (e.key === "Escape" || e.key === "Esc")
        } else {
            isEscape = (e.keyCode === 27)
        }
        if (isEscape && $('body').hasClass('has-active-modal')) {
            toggleModal('.active-modal');
        }
    });

});