import './styles/app-homepage.css';
// start the Stimulus application
import './bootstrap';
import './javascript/modals.js';
import './javascript/jquery-extension/jquery-toggle-disable-button.js';

// eslint-disable-next-line import/no-extraneous-dependencies
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.min.js');

// Adds global jQuery for usage
const $ = require('jquery');
global.$ = global.jQuery = $;
